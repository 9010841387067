import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {TypeAnimation} from 'react-type-animation';

export default function Typing({onComplete}) {
    const [fadeOut, setFadeOut] = useState(false);
    let [removeContainer, setRemoveContainer] = useState(false);

    const handleAnimationEnd = () => {
        setRemoveContainer(true); // Remove the entire container once the animation ends
    };

    if (removeContainer) {
        return null; // Completely remove the component when the container is removed
    }

    return (
        <div className={`typing typing-text ${fadeOut ? 'fade-to-black' : ''}`} onAnimationEnd={handleAnimationEnd}>
            {/* Your typing animation component */}
            <TypeAnimation
                sequence={[
                    'Finding things that can’t be found', // Types 'One'
                    1000,  // Waits 1s
                    'Finding things that can be found', // Deletes 'One' and types 'Two'
                    2000,  // Waits 2s
                    'Preserving the things that matter most', // Types 'Three' without deleting 'Two'
                    2000,
                    () => {
                        setFadeOut(true); // Trigger fade-to-black effect
                        console.log('Sequence completed');
                        if (onComplete) {
                            onComplete(); // Notify parent that animation is complete
                        }
                    }
                ]}
                wrapper="span"
                cursor={true}
            />
        </div>
    );
}
