import React, {Component} from 'react';
import Typing from '../components/Typing';
import 'bootstrap/dist/css/bootstrap.css';
import "./index.css";

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fadeIn: false, // Initialize fadeIn state
            width: window.innerWidth,
            height: window.innerHeight
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleTypingComplete = this.handleTypingComplete.bind(this);
    }

    updateDimensions() {
        if (window.innerWidth < 768) { // Consider mobile if screen width is less than 768px
            this.setState({width: 300, height: 300});
        } else {
            this.setState({width: 700, height: 500});
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    handleTypingComplete() {
        // Wait for the fade-out effect to complete, then trigger fade-in
        setTimeout(() => {
            this.setState({fadeIn: true});
        }, 2000); // Adjust delay to match the fade-out duration
    }

    render() {
        const {fadeIn} = this.state;

        return (
            <div className="index">
                <div>
                    <Typing onComplete={this.handleTypingComplete}/>
                </div>
                <div className={"black-bg"}>
                    <div className={`amber ${fadeIn ? 'fade-in' : ''}`}>
                        {/* <div className='row'>
                            <b>Welcome to Amber Triangle</b>
                        </div> */}
                        <div className='row text-center'>
                            <img src="/images/logo.png" className='main-image' alt="Logo"/>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Index;
